import revive_payload_client_qJJFtevaGh from "/app/node_modules/.pnpm/nuxt@3.10.3_@parcel+watcher@2.4.1_@types+node@20.11.24_encoding@0.1.13_eslint@8.57.0_op_7389cdbc4aa1eccdb186f846b074abf7/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_s9EIwKBACb from "/app/node_modules/.pnpm/nuxt@3.10.3_@parcel+watcher@2.4.1_@types+node@20.11.24_encoding@0.1.13_eslint@8.57.0_op_7389cdbc4aa1eccdb186f846b074abf7/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_bIx9I25rr3 from "/app/node_modules/.pnpm/nuxt@3.10.3_@parcel+watcher@2.4.1_@types+node@20.11.24_encoding@0.1.13_eslint@8.57.0_op_7389cdbc4aa1eccdb186f846b074abf7/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_JRPjYuzGR9 from "/app/node_modules/.pnpm/nuxt-site-config@2.2.11_@nuxt+devtools@1.0.8_nuxt@3.10.3_@parcel+watcher@2.4.1_@types+n_eb39c0d38ed457208ee34d958f4ba191/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.mjs";
import payload_client_i1Pb55ywVy from "/app/node_modules/.pnpm/nuxt@3.10.3_@parcel+watcher@2.4.1_@types+node@20.11.24_encoding@0.1.13_eslint@8.57.0_op_7389cdbc4aa1eccdb186f846b074abf7/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_oGARIOMYHn from "/app/node_modules/.pnpm/nuxt@3.10.3_@parcel+watcher@2.4.1_@types+node@20.11.24_encoding@0.1.13_eslint@8.57.0_op_7389cdbc4aa1eccdb186f846b074abf7/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import components_plugin_KR1HBZs4kY from "/app/.nuxt/components.plugin.mjs";
import prefetch_client_6166y1ceVj from "/app/node_modules/.pnpm/nuxt@3.10.3_@parcel+watcher@2.4.1_@types+node@20.11.24_encoding@0.1.13_eslint@8.57.0_op_7389cdbc4aa1eccdb186f846b074abf7/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_2i9rymRS7J from "/app/node_modules/.pnpm/nuxt-jsonld@2.0.8_typescript@5.3.3/node_modules/nuxt-jsonld/dist/runtime/plugin.js";
import i18n_VTMiIljjZP from "/app/node_modules/.pnpm/@nuxtjs+i18n@8.1.1_rollup@4.12.0_vue@3.4.21_typescript@5.3.3_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import nuxt_plugin_5o2SKMPArs from "/app/node_modules/.pnpm/nuxt-delay-hydration@1.3.3_rollup@4.12.0/node_modules/nuxt-delay-hydration/dist/runtime/nuxt-plugin.mjs";
import plugin_T7GYDMp7nN from "/app/node_modules/.pnpm/@nuxtjs+device@3.1.1_rollup@4.12.0/node_modules/@nuxtjs/device/dist/runtime/plugin.mjs";
import plugin_EEJUhGXNu0 from "/app/node_modules/.pnpm/@zadigetvoltaire+nuxt-gtm@0.0.13_nuxt@3.10.3_@parcel+watcher@2.4.1_@types+node@20.11.24_a34dcec3b12de11092e955c52279e607/node_modules/@zadigetvoltaire/nuxt-gtm/dist/runtime/plugin.mjs";
import plugin_client_0GUAlM6s5X from "/app/node_modules/.pnpm/@nuxtjs+color-mode@3.3.2_rollup@4.12.0/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.mjs";
import plugin_FRmGFsEaPh from "/app/.nuxt/plugin.mjs";
import log_monitor_JFLCWq3mfG from "/app/modules/log/runtime/plugins/log.monitor.ts";
import log_client_g5mOc0qTNy from "/app/modules/log/runtime/plugins/log.client.ts";
import auth_user_context_BJQK4WVKVx from "/app/modules/session/runtime/plugins/auth-user-context.ts";
import chunk_reload_client_n5A2fzSaJG from "/app/node_modules/.pnpm/nuxt@3.10.3_@parcel+watcher@2.4.1_@types+node@20.11.24_encoding@0.1.13_eslint@8.57.0_op_7389cdbc4aa1eccdb186f846b074abf7/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import aos_client_rDSYU13oOX from "/app/plugins/aos.client.ts";
import color_picker_client_exgT1GPKhS from "/app/plugins/color-picker.client.ts";
import common_YciHauaUFF from "/app/plugins/common.ts";
import darkTheme_FufQGKMDXx from "/app/plugins/darkTheme.ts";
import date_picker_components_VQzhMlbBYA from "/app/plugins/date-picker-components.ts";
import error_ldt3PQauZ0 from "/app/plugins/error.ts";
import eventTracker_client_u7BW3SmuVC from "/app/plugins/eventTracker.client.ts";
import formatter_upj5Ck7VLP from "/app/plugins/formatter.ts";
import i18n_VfGcjrvSkj from "/app/plugins/i18n.ts";
import markdown_client_LcyP1djKNO from "/app/plugins/markdown.client.ts";
import mitt_S0QU5gJPTl from "/app/plugins/mitt.ts";
import scroll_to_top_client_RWxA4ywK3M from "/app/plugins/scroll-to-top.client.ts";
import seo_6xeD0pAsTm from "/app/plugins/seo.ts";
import toast_ysMjUcU7eJ from "/app/plugins/toast.ts";
import veevalidate_components_HZdjzYOWQC from "/app/plugins/veevalidate-components.ts";
import veevalidate_rules_Xc2IpxFdQq from "/app/plugins/veevalidate-rules.ts";
import vue_awesome_paginate_9p6HiiW96p from "/app/plugins/vue-awesome.paginate.ts";
import websocket_client_IwtQxfNJbn from "/app/plugins/websocket.client.ts";
export default [
  revive_payload_client_qJJFtevaGh,
  unhead_s9EIwKBACb,
  router_bIx9I25rr3,
  _0_siteConfig_JRPjYuzGR9,
  payload_client_i1Pb55ywVy,
  check_outdated_build_client_oGARIOMYHn,
  components_plugin_KR1HBZs4kY,
  prefetch_client_6166y1ceVj,
  plugin_2i9rymRS7J,
  i18n_VTMiIljjZP,
  nuxt_plugin_5o2SKMPArs,
  plugin_T7GYDMp7nN,
  plugin_EEJUhGXNu0,
  plugin_client_0GUAlM6s5X,
  plugin_FRmGFsEaPh,
  log_monitor_JFLCWq3mfG,
  log_client_g5mOc0qTNy,
  auth_user_context_BJQK4WVKVx,
  chunk_reload_client_n5A2fzSaJG,
  aos_client_rDSYU13oOX,
  color_picker_client_exgT1GPKhS,
  common_YciHauaUFF,
  darkTheme_FufQGKMDXx,
  date_picker_components_VQzhMlbBYA,
  error_ldt3PQauZ0,
  eventTracker_client_u7BW3SmuVC,
  formatter_upj5Ck7VLP,
  i18n_VfGcjrvSkj,
  markdown_client_LcyP1djKNO,
  mitt_S0QU5gJPTl,
  scroll_to_top_client_RWxA4ywK3M,
  seo_6xeD0pAsTm,
  toast_ysMjUcU7eJ,
  veevalidate_components_HZdjzYOWQC,
  veevalidate_rules_Xc2IpxFdQq,
  vue_awesome_paginate_9p6HiiW96p,
  websocket_client_IwtQxfNJbn
]