import { default as addMxYtWfQqmOMeta } from "/app/pages/admin/campaign/add.vue?macro=true";
import { default as _91id_93u6TA2i5bnPMeta } from "/app/pages/admin/campaign/edit/[id].vue?macro=true";
import { default as indexjGIepczFNQMeta } from "/app/pages/admin/campaign/index.vue?macro=true";
import { default as addTqfT5OwXyyMeta } from "/app/pages/admin/community/add.vue?macro=true";
import { default as commentsdDcPmfSfmhMeta } from "/app/pages/admin/community/comments.vue?macro=true";
import { default as communityw1C3Bv2c3XMeta } from "/app/pages/admin/community/community.vue?macro=true";
import { default as _91id_93g8EywmKZWCMeta } from "/app/pages/admin/community/edit/[id].vue?macro=true";
import { default as posts0TBAf6r7SWMeta } from "/app/pages/admin/community/posts.vue?macro=true";
import { default as usersesdWH9o2qTMeta } from "/app/pages/admin/community/users.vue?macro=true";
import { default as addsZbRyQn0S1Meta } from "/app/pages/admin/company-api/add.vue?macro=true";
import { default as _91id_93qElwJdO2qxMeta } from "/app/pages/admin/company-api/edit/[id].vue?macro=true";
import { default as emailEoE2wsIDIqMeta } from "/app/pages/admin/email.vue?macro=true";
import { default as event_45categoryNnnTuFhpy1Meta } from "/app/pages/admin/event-category.vue?macro=true";
import { default as add2RAWjKrbLrMeta } from "/app/pages/admin/events/add.vue?macro=true";
import { default as _91id_93JmpY3ZUauaMeta } from "/app/pages/admin/events/edit/[id].vue?macro=true";
import { default as indexFSe3tm7kZtMeta } from "/app/pages/admin/events/index.vue?macro=true";
import { default as indexDjWFfCGLJPMeta } from "/app/pages/admin/news-api/index.vue?macro=true";
import { default as newsItm3AFL6XIMeta } from "/app/pages/admin/news.vue?macro=true";
import { default as indexxXD4yeg1kGMeta } from "/app/pages/admin/other/index.vue?macro=true";
import { default as testMoVrQybpy7Meta } from "/app/pages/admin/other/test.vue?macro=true";
import { default as popupTaLTwX7MyHMeta } from "/app/pages/admin/popup.vue?macro=true";
import { default as _91id_93Wra5hHpkXSMeta } from "/app/pages/admin/post/edit/[id].vue?macro=true";
import { default as project_45categoryiWkCqsrPUPMeta } from "/app/pages/admin/project-category.vue?macro=true";
import { default as addjw8BKQpXoqMeta } from "/app/pages/admin/project/add.vue?macro=true";
import { default as _91id_93mCFEJ3dnUnMeta } from "/app/pages/admin/project/edit/[id].vue?macro=true";
import { default as indexiCtVJbon5IMeta } from "/app/pages/admin/project/index.vue?macro=true";
import { default as indexKWgVUQDwkYMeta } from "/app/pages/admin/reports/index.vue?macro=true";
import { default as registered_45usersRtJv8unE2IMeta } from "/app/pages/admin/reports/registered-users.vue?macro=true";
import { default as tagsKRwpVRBGK8Meta } from "/app/pages/admin/tags.vue?macro=true";
import { default as add12xrtSm9qrMeta } from "/app/pages/admin/token/add.vue?macro=true";
import { default as _91id_93nCoYx26BwIMeta } from "/app/pages/admin/token/edit/[id].vue?macro=true";
import { default as indexMUmcNVbyGiMeta } from "/app/pages/admin/token/index.vue?macro=true";
import { default as _91id_938Z77RhLRKsMeta } from "/app/pages/admin/user/edit/[id].vue?macro=true";
import { default as _91id_93U7UuNLy1DDMeta } from "/app/pages/admin/user/referral/[id].vue?macro=true";
import { default as addcahtmjbi5uMeta } from "/app/pages/admin/wiki/add.vue?macro=true";
import { default as _91id_935nx6c7GxC3Meta } from "/app/pages/admin/wiki/edit/[id].vue?macro=true";
import { default as index4uRcGrPScQMeta } from "/app/pages/admin/wiki/index.vue?macro=true";
import { default as xmasqwGCW3VStSMeta } from "/app/pages/admin/xmas.vue?macro=true";
import { default as cardano_45gathering_45barcelonaIB54C8f6lxMeta } from "/app/pages/cardano-gathering-barcelona.vue?macro=true";
import { default as cardano_45spot_45feedback_45formrUU1JknC1wMeta } from "/app/pages/cardano-spot-feedback-form.vue?macro=true";
import { default as cardano_45summit_452023_45livestreamMnFkA9Pfd2Meta } from "/app/pages/cardano-summit-2023-livestream.vue?macro=true";
import { default as cardano_45summit_452023_45recapkphiBxhL5TMeta } from "/app/pages/cardano-summit-2023-recap.vue?macro=true";
import { default as communitiesh1xDbmOKfrMeta } from "/app/pages/communities.vue?macro=true";
import { default as _91id_93ty07sVQHIRMeta } from "/app/pages/community-feed/[id].vue?macro=true";
import { default as eulaa3rlOml7gPMeta } from "/app/pages/eula.vue?macro=true";
import { default as eventsk5yx3AVVmZMeta } from "/app/pages/events.vue?macro=true";
import { default as exploreRKECn8OWt4Meta } from "/app/pages/explore.vue?macro=true";
import { default as home_45feedCOx9rwE9N3Meta } from "/app/pages/home-feed.vue?macro=true";
import { default as indexN6pT4Un8hYMeta } from "/app/pages/index.vue?macro=true";
import { default as landingrvSETkGqnoMeta } from "/app/pages/landing.vue?macro=true";
import { default as _91id_93CdhVbPiRnyMeta } from "/app/pages/live-stream/[id].vue?macro=true";
import { default as _91_46_46_46slug_93j2gXefvOK9Meta } from "/app/pages/ls/[...slug].vue?macro=true";
import { default as market_45statusZKrLrP0NR0Meta } from "/app/pages/market-status.vue?macro=true";
import { default as news_45apiq3FNbV8QcyMeta } from "/app/pages/news-api.vue?macro=true";
import { default as indexKaVKCQDUK5Meta } from "/app/pages/news-feed/index.vue?macro=true";
import { default as _91slug_93h6ecm9GkHAMeta } from "/app/pages/news/[slug].vue?macro=true";
import { default as editPukIXt8PypMeta } from "/app/pages/post/[slug]/edit.vue?macro=true";
import { default as index9TeG384RYsMeta } from "/app/pages/post/[slug]/index.vue?macro=true";
import { default as privacy_45noticegeuSyXysWNMeta } from "/app/pages/privacy-notice.vue?macro=true";
import { default as _91id_93irVUbxHuAaMeta } from "/app/pages/project-library/[id].vue?macro=true";
import { default as _91slug_93UgNoDN7oUoMeta } from "/app/pages/project/[slug].vue?macro=true";
import { default as searchfcnTL5fl5XMeta } from "/app/pages/search.vue?macro=true";
import { default as technical_45feedbackuTtUMc8w0OMeta } from "/app/pages/technical-feedback.vue?macro=true";
import { default as terms_45of_45useA3Owpnmw23Meta } from "/app/pages/terms-of-use.vue?macro=true";
import { default as _91id_93qjicQeGjhhMeta } from "/app/pages/token/[id].vue?macro=true";
import { default as unsubscribeuH6r0XUkMkMeta } from "/app/pages/unsubscribe.vue?macro=true";
import { default as _91id_93RVBZU44zsvMeta } from "/app/pages/user/[id].vue?macro=true";
import { default as articlesT9jr87VNjzMeta } from "/app/pages/user/articles.vue?macro=true";
import { default as blocked_45usersXLQATV4oF1Meta } from "/app/pages/user/blocked-users.vue?macro=true";
import { default as bookmarksUeWKsfEu4ZMeta } from "/app/pages/user/bookmarks.vue?macro=true";
import { default as communitiesn9ehayBcnwMeta } from "/app/pages/user/communities.vue?macro=true";
import { default as _91hash_93iKTLTIW3ZEMeta } from "/app/pages/user/complete/[hash].vue?macro=true";
import { default as create_45articlezADS7UwfPwMeta } from "/app/pages/user/create-article.vue?macro=true";
import { default as create_45communityK8yDdsrKXKMeta } from "/app/pages/user/create-community.vue?macro=true";
import { default as create_45eventD8BbloxvDuMeta } from "/app/pages/user/create-event.vue?macro=true";
import { default as create_45projecti0Kmc80IgWMeta } from "/app/pages/user/create-project.vue?macro=true";
import { default as create_45tokenj0JJBsmmjsMeta } from "/app/pages/user/create-token.vue?macro=true";
import { default as _91slug_9376w3MP4yhHMeta } from "/app/pages/user/edit-article/[slug].vue?macro=true";
import { default as _91id_93wLvbN9BZy8Meta } from "/app/pages/user/edit-community/[id].vue?macro=true";
import { default as _91id_93Kd4HGOJgVnMeta } from "/app/pages/user/edit-event/[id].vue?macro=true";
import { default as _91slug_93C5CtYd2TPaMeta } from "/app/pages/user/edit-project/[slug].vue?macro=true";
import { default as _91id_939pEgEkG1yyMeta } from "/app/pages/user/edit-token/[id].vue?macro=true";
import { default as eventsiTmbXtGfOLMeta } from "/app/pages/user/events.vue?macro=true";
import { default as loginR70WB70wCiMeta } from "/app/pages/user/login.vue?macro=true";
import { default as logoutaviNfKdBL2Meta } from "/app/pages/user/logout.vue?macro=true";
import { default as _91hash_93vajZBYv9lQMeta } from "/app/pages/user/new-user/[hash].vue?macro=true";
import { default as passwordckK2hF554oMeta } from "/app/pages/user/password.vue?macro=true";
import { default as postsJ4g2Ow1JvzMeta } from "/app/pages/user/posts.vue?macro=true";
import { default as profileWu1o0nyKd6Meta } from "/app/pages/user/profile.vue?macro=true";
import { default as projectsjG0tSCfZ73Meta } from "/app/pages/user/projects.vue?macro=true";
import { default as register4GabFBgyAsMeta } from "/app/pages/user/register.vue?macro=true";
import { default as _91hash_93s6gk9zTWnnMeta } from "/app/pages/user/reset/[hash].vue?macro=true";
import { default as settingsBeRaS6K39rMeta } from "/app/pages/user/settings.vue?macro=true";
import { default as tokensZp1Bo0nBuJMeta } from "/app/pages/user/tokens.vue?macro=true";
import { default as dappsPfPNbm6i9SMeta } from "/app/pages/wiki/dapps.vue?macro=true";
import { default as indexgfqNjlqG7TMeta } from "/app/pages/wiki/index.vue?macro=true";
import { default as _91id_93ERSjn3Z7LcMeta } from "/app/pages/wiki/roadmap/[id].vue?macro=true";
import { default as indexq0Y0oMklcrMeta } from "/app/pages/wiki/roadmap/index.vue?macro=true";
import { default as _91term_93CazlAnrlhVMeta } from "/app/pages/wiki/vocabulary/[letter]/[term].vue?macro=true";
import { default as indexzm16WkKjLWMeta } from "/app/pages/wiki/vocabulary/[letter]/index.vue?macro=true";
import { default as nuxt_643_4610_463__64parcel_43watcher_642_464_461__64types_43node_6420_4611_4624_encoding_640_461_4613_eslint_648_4657bWx3yCoGtnMeta } from "/app/node_modules/.pnpm/nuxt@3.10.3_@parcel+watcher@2.4.1_@types+node@20.11.24_encoding@0.1.13_eslint@8.57.0_op_7389cdbc4aa1eccdb186f846b074abf7/node_modules/nuxt/dist/pages/runtime/component-stub?macro=true";
export default [
  {
    name: addMxYtWfQqmOMeta?.name ?? "admin-campaign-add___en",
    path: addMxYtWfQqmOMeta?.path ?? "/admin/campaign/add",
    meta: addMxYtWfQqmOMeta || {},
    alias: addMxYtWfQqmOMeta?.alias || [],
    redirect: addMxYtWfQqmOMeta?.redirect,
    component: () => import("/app/pages/admin/campaign/add.vue").then(m => m.default || m)
  },
  {
    name: _91id_93u6TA2i5bnPMeta?.name ?? "admin-campaign-edit-id___en",
    path: _91id_93u6TA2i5bnPMeta?.path ?? "/admin/campaign/edit/:id()",
    meta: _91id_93u6TA2i5bnPMeta || {},
    alias: _91id_93u6TA2i5bnPMeta?.alias || [],
    redirect: _91id_93u6TA2i5bnPMeta?.redirect,
    component: () => import("/app/pages/admin/campaign/edit/[id].vue").then(m => m.default || m)
  },
  {
    name: indexjGIepczFNQMeta?.name ?? "admin-campaign___en",
    path: indexjGIepczFNQMeta?.path ?? "/admin/campaign",
    meta: indexjGIepczFNQMeta || {},
    alias: indexjGIepczFNQMeta?.alias || [],
    redirect: indexjGIepczFNQMeta?.redirect,
    component: () => import("/app/pages/admin/campaign/index.vue").then(m => m.default || m)
  },
  {
    name: addTqfT5OwXyyMeta?.name ?? "admin-community-add___en",
    path: addTqfT5OwXyyMeta?.path ?? "/admin/community/add",
    meta: addTqfT5OwXyyMeta || {},
    alias: addTqfT5OwXyyMeta?.alias || [],
    redirect: addTqfT5OwXyyMeta?.redirect,
    component: () => import("/app/pages/admin/community/add.vue").then(m => m.default || m)
  },
  {
    name: commentsdDcPmfSfmhMeta?.name ?? "admin-community-comments___en",
    path: commentsdDcPmfSfmhMeta?.path ?? "/admin/community/comments",
    meta: commentsdDcPmfSfmhMeta || {},
    alias: commentsdDcPmfSfmhMeta?.alias || [],
    redirect: commentsdDcPmfSfmhMeta?.redirect,
    component: () => import("/app/pages/admin/community/comments.vue").then(m => m.default || m)
  },
  {
    name: communityw1C3Bv2c3XMeta?.name ?? "admin-community-community___en",
    path: communityw1C3Bv2c3XMeta?.path ?? "/admin/community/community",
    meta: communityw1C3Bv2c3XMeta || {},
    alias: communityw1C3Bv2c3XMeta?.alias || [],
    redirect: communityw1C3Bv2c3XMeta?.redirect,
    component: () => import("/app/pages/admin/community/community.vue").then(m => m.default || m)
  },
  {
    name: _91id_93g8EywmKZWCMeta?.name ?? "admin-community-edit-id___en",
    path: _91id_93g8EywmKZWCMeta?.path ?? "/admin/community/edit/:id()",
    meta: _91id_93g8EywmKZWCMeta || {},
    alias: _91id_93g8EywmKZWCMeta?.alias || [],
    redirect: _91id_93g8EywmKZWCMeta?.redirect,
    component: () => import("/app/pages/admin/community/edit/[id].vue").then(m => m.default || m)
  },
  {
    name: posts0TBAf6r7SWMeta?.name ?? "admin-community-posts___en",
    path: posts0TBAf6r7SWMeta?.path ?? "/admin/community/posts",
    meta: posts0TBAf6r7SWMeta || {},
    alias: posts0TBAf6r7SWMeta?.alias || [],
    redirect: posts0TBAf6r7SWMeta?.redirect,
    component: () => import("/app/pages/admin/community/posts.vue").then(m => m.default || m)
  },
  {
    name: usersesdWH9o2qTMeta?.name ?? "admin-community-users___en",
    path: usersesdWH9o2qTMeta?.path ?? "/admin/community/users",
    meta: usersesdWH9o2qTMeta || {},
    alias: usersesdWH9o2qTMeta?.alias || [],
    redirect: usersesdWH9o2qTMeta?.redirect,
    component: () => import("/app/pages/admin/community/users.vue").then(m => m.default || m)
  },
  {
    name: addsZbRyQn0S1Meta?.name ?? "admin-company-api-add___en",
    path: addsZbRyQn0S1Meta?.path ?? "/admin/company-api/add",
    meta: addsZbRyQn0S1Meta || {},
    alias: addsZbRyQn0S1Meta?.alias || [],
    redirect: addsZbRyQn0S1Meta?.redirect,
    component: () => import("/app/pages/admin/company-api/add.vue").then(m => m.default || m)
  },
  {
    name: _91id_93qElwJdO2qxMeta?.name ?? "admin-company-api-edit-id___en",
    path: _91id_93qElwJdO2qxMeta?.path ?? "/admin/company-api/edit/:id()",
    meta: _91id_93qElwJdO2qxMeta || {},
    alias: _91id_93qElwJdO2qxMeta?.alias || [],
    redirect: _91id_93qElwJdO2qxMeta?.redirect,
    component: () => import("/app/pages/admin/company-api/edit/[id].vue").then(m => m.default || m)
  },
  {
    name: emailEoE2wsIDIqMeta?.name ?? "admin-email___en",
    path: emailEoE2wsIDIqMeta?.path ?? "/admin/email",
    meta: emailEoE2wsIDIqMeta || {},
    alias: emailEoE2wsIDIqMeta?.alias || [],
    redirect: emailEoE2wsIDIqMeta?.redirect,
    component: () => import("/app/pages/admin/email.vue").then(m => m.default || m)
  },
  {
    name: event_45categoryNnnTuFhpy1Meta?.name ?? "admin-event-category___en",
    path: event_45categoryNnnTuFhpy1Meta?.path ?? "/admin/event-category",
    meta: event_45categoryNnnTuFhpy1Meta || {},
    alias: event_45categoryNnnTuFhpy1Meta?.alias || [],
    redirect: event_45categoryNnnTuFhpy1Meta?.redirect,
    component: () => import("/app/pages/admin/event-category.vue").then(m => m.default || m)
  },
  {
    name: add2RAWjKrbLrMeta?.name ?? "admin-events-add___en",
    path: add2RAWjKrbLrMeta?.path ?? "/admin/events/add",
    meta: add2RAWjKrbLrMeta || {},
    alias: add2RAWjKrbLrMeta?.alias || [],
    redirect: add2RAWjKrbLrMeta?.redirect,
    component: () => import("/app/pages/admin/events/add.vue").then(m => m.default || m)
  },
  {
    name: _91id_93JmpY3ZUauaMeta?.name ?? "admin-events-edit-id___en",
    path: _91id_93JmpY3ZUauaMeta?.path ?? "/admin/events/edit/:id()",
    meta: _91id_93JmpY3ZUauaMeta || {},
    alias: _91id_93JmpY3ZUauaMeta?.alias || [],
    redirect: _91id_93JmpY3ZUauaMeta?.redirect,
    component: () => import("/app/pages/admin/events/edit/[id].vue").then(m => m.default || m)
  },
  {
    name: indexFSe3tm7kZtMeta?.name ?? "admin-events___en",
    path: indexFSe3tm7kZtMeta?.path ?? "/admin/events",
    meta: indexFSe3tm7kZtMeta || {},
    alias: indexFSe3tm7kZtMeta?.alias || [],
    redirect: indexFSe3tm7kZtMeta?.redirect,
    component: () => import("/app/pages/admin/events/index.vue").then(m => m.default || m)
  },
  {
    name: indexDjWFfCGLJPMeta?.name ?? "admin-news-api___en",
    path: indexDjWFfCGLJPMeta?.path ?? "/admin/news-api",
    meta: indexDjWFfCGLJPMeta || {},
    alias: indexDjWFfCGLJPMeta?.alias || [],
    redirect: indexDjWFfCGLJPMeta?.redirect,
    component: () => import("/app/pages/admin/news-api/index.vue").then(m => m.default || m)
  },
  {
    name: newsItm3AFL6XIMeta?.name ?? "admin-news___en",
    path: newsItm3AFL6XIMeta?.path ?? "/admin/news",
    meta: newsItm3AFL6XIMeta || {},
    alias: newsItm3AFL6XIMeta?.alias || [],
    redirect: newsItm3AFL6XIMeta?.redirect,
    component: () => import("/app/pages/admin/news.vue").then(m => m.default || m)
  },
  {
    name: indexxXD4yeg1kGMeta?.name ?? "admin-other___en",
    path: indexxXD4yeg1kGMeta?.path ?? "/admin/other",
    meta: indexxXD4yeg1kGMeta || {},
    alias: indexxXD4yeg1kGMeta?.alias || [],
    redirect: indexxXD4yeg1kGMeta?.redirect,
    component: () => import("/app/pages/admin/other/index.vue").then(m => m.default || m)
  },
  {
    name: testMoVrQybpy7Meta?.name ?? "admin-other-test___en",
    path: testMoVrQybpy7Meta?.path ?? "/admin/other/test",
    meta: testMoVrQybpy7Meta || {},
    alias: testMoVrQybpy7Meta?.alias || [],
    redirect: testMoVrQybpy7Meta?.redirect,
    component: () => import("/app/pages/admin/other/test.vue").then(m => m.default || m)
  },
  {
    name: popupTaLTwX7MyHMeta?.name ?? "admin-popup___en",
    path: popupTaLTwX7MyHMeta?.path ?? "/admin/popup",
    meta: popupTaLTwX7MyHMeta || {},
    alias: popupTaLTwX7MyHMeta?.alias || [],
    redirect: popupTaLTwX7MyHMeta?.redirect,
    component: () => import("/app/pages/admin/popup.vue").then(m => m.default || m)
  },
  {
    name: _91id_93Wra5hHpkXSMeta?.name ?? "admin-post-edit-id___en",
    path: _91id_93Wra5hHpkXSMeta?.path ?? "/admin/post/edit/:id()",
    meta: _91id_93Wra5hHpkXSMeta || {},
    alias: _91id_93Wra5hHpkXSMeta?.alias || [],
    redirect: _91id_93Wra5hHpkXSMeta?.redirect,
    component: () => import("/app/pages/admin/post/edit/[id].vue").then(m => m.default || m)
  },
  {
    name: project_45categoryiWkCqsrPUPMeta?.name ?? "admin-project-category___en",
    path: project_45categoryiWkCqsrPUPMeta?.path ?? "/admin/project-category",
    meta: project_45categoryiWkCqsrPUPMeta || {},
    alias: project_45categoryiWkCqsrPUPMeta?.alias || [],
    redirect: project_45categoryiWkCqsrPUPMeta?.redirect,
    component: () => import("/app/pages/admin/project-category.vue").then(m => m.default || m)
  },
  {
    name: addjw8BKQpXoqMeta?.name ?? "admin-project-add___en",
    path: addjw8BKQpXoqMeta?.path ?? "/admin/project/add",
    meta: addjw8BKQpXoqMeta || {},
    alias: addjw8BKQpXoqMeta?.alias || [],
    redirect: addjw8BKQpXoqMeta?.redirect,
    component: () => import("/app/pages/admin/project/add.vue").then(m => m.default || m)
  },
  {
    name: _91id_93mCFEJ3dnUnMeta?.name ?? "admin-project-edit-id___en",
    path: _91id_93mCFEJ3dnUnMeta?.path ?? "/admin/project/edit/:id()",
    meta: _91id_93mCFEJ3dnUnMeta || {},
    alias: _91id_93mCFEJ3dnUnMeta?.alias || [],
    redirect: _91id_93mCFEJ3dnUnMeta?.redirect,
    component: () => import("/app/pages/admin/project/edit/[id].vue").then(m => m.default || m)
  },
  {
    name: indexiCtVJbon5IMeta?.name ?? "admin-project___en",
    path: indexiCtVJbon5IMeta?.path ?? "/admin/project",
    meta: indexiCtVJbon5IMeta || {},
    alias: indexiCtVJbon5IMeta?.alias || [],
    redirect: indexiCtVJbon5IMeta?.redirect,
    component: () => import("/app/pages/admin/project/index.vue").then(m => m.default || m)
  },
  {
    name: indexKWgVUQDwkYMeta?.name ?? "admin-reports___en",
    path: indexKWgVUQDwkYMeta?.path ?? "/admin/reports",
    meta: indexKWgVUQDwkYMeta || {},
    alias: indexKWgVUQDwkYMeta?.alias || [],
    redirect: indexKWgVUQDwkYMeta?.redirect,
    component: () => import("/app/pages/admin/reports/index.vue").then(m => m.default || m)
  },
  {
    name: registered_45usersRtJv8unE2IMeta?.name ?? "admin-reports-registered-users___en",
    path: registered_45usersRtJv8unE2IMeta?.path ?? "/admin/reports/registered-users",
    meta: registered_45usersRtJv8unE2IMeta || {},
    alias: registered_45usersRtJv8unE2IMeta?.alias || [],
    redirect: registered_45usersRtJv8unE2IMeta?.redirect,
    component: () => import("/app/pages/admin/reports/registered-users.vue").then(m => m.default || m)
  },
  {
    name: tagsKRwpVRBGK8Meta?.name ?? "admin-tags___en",
    path: tagsKRwpVRBGK8Meta?.path ?? "/admin/tags",
    meta: tagsKRwpVRBGK8Meta || {},
    alias: tagsKRwpVRBGK8Meta?.alias || [],
    redirect: tagsKRwpVRBGK8Meta?.redirect,
    component: () => import("/app/pages/admin/tags.vue").then(m => m.default || m)
  },
  {
    name: add12xrtSm9qrMeta?.name ?? "admin-token-add___en",
    path: add12xrtSm9qrMeta?.path ?? "/admin/token/add",
    meta: add12xrtSm9qrMeta || {},
    alias: add12xrtSm9qrMeta?.alias || [],
    redirect: add12xrtSm9qrMeta?.redirect,
    component: () => import("/app/pages/admin/token/add.vue").then(m => m.default || m)
  },
  {
    name: _91id_93nCoYx26BwIMeta?.name ?? "admin-token-edit-id___en",
    path: _91id_93nCoYx26BwIMeta?.path ?? "/admin/token/edit/:id()",
    meta: _91id_93nCoYx26BwIMeta || {},
    alias: _91id_93nCoYx26BwIMeta?.alias || [],
    redirect: _91id_93nCoYx26BwIMeta?.redirect,
    component: () => import("/app/pages/admin/token/edit/[id].vue").then(m => m.default || m)
  },
  {
    name: indexMUmcNVbyGiMeta?.name ?? "admin-token___en",
    path: indexMUmcNVbyGiMeta?.path ?? "/admin/token",
    meta: indexMUmcNVbyGiMeta || {},
    alias: indexMUmcNVbyGiMeta?.alias || [],
    redirect: indexMUmcNVbyGiMeta?.redirect,
    component: () => import("/app/pages/admin/token/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_938Z77RhLRKsMeta?.name ?? "admin-user-edit-id___en",
    path: _91id_938Z77RhLRKsMeta?.path ?? "/admin/user/edit/:id()",
    meta: _91id_938Z77RhLRKsMeta || {},
    alias: _91id_938Z77RhLRKsMeta?.alias || [],
    redirect: _91id_938Z77RhLRKsMeta?.redirect,
    component: () => import("/app/pages/admin/user/edit/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93U7UuNLy1DDMeta?.name ?? "admin-user-referral-id___en",
    path: _91id_93U7UuNLy1DDMeta?.path ?? "/admin/user/referral/:id()",
    meta: _91id_93U7UuNLy1DDMeta || {},
    alias: _91id_93U7UuNLy1DDMeta?.alias || [],
    redirect: _91id_93U7UuNLy1DDMeta?.redirect,
    component: () => import("/app/pages/admin/user/referral/[id].vue").then(m => m.default || m)
  },
  {
    name: addcahtmjbi5uMeta?.name ?? "admin-wiki-add___en",
    path: addcahtmjbi5uMeta?.path ?? "/admin/wiki/add",
    meta: addcahtmjbi5uMeta || {},
    alias: addcahtmjbi5uMeta?.alias || [],
    redirect: addcahtmjbi5uMeta?.redirect,
    component: () => import("/app/pages/admin/wiki/add.vue").then(m => m.default || m)
  },
  {
    name: _91id_935nx6c7GxC3Meta?.name ?? "admin-wiki-edit-id___en",
    path: _91id_935nx6c7GxC3Meta?.path ?? "/admin/wiki/edit/:id()",
    meta: _91id_935nx6c7GxC3Meta || {},
    alias: _91id_935nx6c7GxC3Meta?.alias || [],
    redirect: _91id_935nx6c7GxC3Meta?.redirect,
    component: () => import("/app/pages/admin/wiki/edit/[id].vue").then(m => m.default || m)
  },
  {
    name: index4uRcGrPScQMeta?.name ?? "admin-wiki___en",
    path: index4uRcGrPScQMeta?.path ?? "/admin/wiki",
    meta: index4uRcGrPScQMeta || {},
    alias: index4uRcGrPScQMeta?.alias || [],
    redirect: index4uRcGrPScQMeta?.redirect,
    component: () => import("/app/pages/admin/wiki/index.vue").then(m => m.default || m)
  },
  {
    name: xmasqwGCW3VStSMeta?.name ?? "admin-xmas___en",
    path: xmasqwGCW3VStSMeta?.path ?? "/admin/xmas",
    meta: xmasqwGCW3VStSMeta || {},
    alias: xmasqwGCW3VStSMeta?.alias || [],
    redirect: xmasqwGCW3VStSMeta?.redirect,
    component: () => import("/app/pages/admin/xmas.vue").then(m => m.default || m)
  },
  {
    name: cardano_45gathering_45barcelonaIB54C8f6lxMeta?.name ?? "cardano-gathering-barcelona___en",
    path: cardano_45gathering_45barcelonaIB54C8f6lxMeta?.path ?? "/cardano-gathering-barcelona",
    meta: cardano_45gathering_45barcelonaIB54C8f6lxMeta || {},
    alias: cardano_45gathering_45barcelonaIB54C8f6lxMeta?.alias || [],
    redirect: cardano_45gathering_45barcelonaIB54C8f6lxMeta?.redirect,
    component: () => import("/app/pages/cardano-gathering-barcelona.vue").then(m => m.default || m)
  },
  {
    name: cardano_45spot_45feedback_45formrUU1JknC1wMeta?.name ?? "cardano-spot-feedback-form___en",
    path: cardano_45spot_45feedback_45formrUU1JknC1wMeta?.path ?? "/cardano-spot-feedback-form",
    meta: cardano_45spot_45feedback_45formrUU1JknC1wMeta || {},
    alias: cardano_45spot_45feedback_45formrUU1JknC1wMeta?.alias || [],
    redirect: cardano_45spot_45feedback_45formrUU1JknC1wMeta?.redirect,
    component: () => import("/app/pages/cardano-spot-feedback-form.vue").then(m => m.default || m)
  },
  {
    name: cardano_45summit_452023_45livestreamMnFkA9Pfd2Meta?.name ?? "cardano-summit-2023-livestream___en",
    path: cardano_45summit_452023_45livestreamMnFkA9Pfd2Meta?.path ?? "/cardano-summit-2023-livestream",
    meta: cardano_45summit_452023_45livestreamMnFkA9Pfd2Meta || {},
    alias: cardano_45summit_452023_45livestreamMnFkA9Pfd2Meta?.alias || [],
    redirect: cardano_45summit_452023_45livestreamMnFkA9Pfd2Meta?.redirect,
    component: () => import("/app/pages/cardano-summit-2023-livestream.vue").then(m => m.default || m)
  },
  {
    name: cardano_45summit_452023_45recapkphiBxhL5TMeta?.name ?? "cardano-summit-2023-recap___en",
    path: cardano_45summit_452023_45recapkphiBxhL5TMeta?.path ?? "/cardano-summit-2023-recap",
    meta: cardano_45summit_452023_45recapkphiBxhL5TMeta || {},
    alias: cardano_45summit_452023_45recapkphiBxhL5TMeta?.alias || [],
    redirect: cardano_45summit_452023_45recapkphiBxhL5TMeta?.redirect,
    component: () => import("/app/pages/cardano-summit-2023-recap.vue").then(m => m.default || m)
  },
  {
    name: communitiesh1xDbmOKfrMeta?.name ?? "communities___en",
    path: communitiesh1xDbmOKfrMeta?.path ?? "/communities",
    meta: communitiesh1xDbmOKfrMeta || {},
    alias: communitiesh1xDbmOKfrMeta?.alias || [],
    redirect: communitiesh1xDbmOKfrMeta?.redirect,
    component: () => import("/app/pages/communities.vue").then(m => m.default || m)
  },
  {
    name: _91id_93ty07sVQHIRMeta?.name ?? "community-feed-id___en",
    path: _91id_93ty07sVQHIRMeta?.path ?? "/community-feed/:id()",
    meta: _91id_93ty07sVQHIRMeta || {},
    alias: _91id_93ty07sVQHIRMeta?.alias || [],
    redirect: _91id_93ty07sVQHIRMeta?.redirect,
    component: () => import("/app/pages/community-feed/[id].vue").then(m => m.default || m)
  },
  {
    name: eulaa3rlOml7gPMeta?.name ?? "eula___en",
    path: eulaa3rlOml7gPMeta?.path ?? "/eula",
    meta: eulaa3rlOml7gPMeta || {},
    alias: eulaa3rlOml7gPMeta?.alias || [],
    redirect: eulaa3rlOml7gPMeta?.redirect,
    component: () => import("/app/pages/eula.vue").then(m => m.default || m)
  },
  {
    name: eventsk5yx3AVVmZMeta?.name ?? "events___en",
    path: eventsk5yx3AVVmZMeta?.path ?? "/events",
    meta: eventsk5yx3AVVmZMeta || {},
    alias: eventsk5yx3AVVmZMeta?.alias || [],
    redirect: eventsk5yx3AVVmZMeta?.redirect,
    component: () => import("/app/pages/events.vue").then(m => m.default || m)
  },
  {
    name: exploreRKECn8OWt4Meta?.name ?? "explore___en",
    path: exploreRKECn8OWt4Meta?.path ?? "/explore",
    meta: exploreRKECn8OWt4Meta || {},
    alias: exploreRKECn8OWt4Meta?.alias || [],
    redirect: exploreRKECn8OWt4Meta?.redirect,
    component: () => import("/app/pages/explore.vue").then(m => m.default || m)
  },
  {
    name: home_45feedCOx9rwE9N3Meta?.name ?? "home-feed___en",
    path: home_45feedCOx9rwE9N3Meta?.path ?? "/home-feed",
    meta: home_45feedCOx9rwE9N3Meta || {},
    alias: home_45feedCOx9rwE9N3Meta?.alias || [],
    redirect: home_45feedCOx9rwE9N3Meta?.redirect,
    component: () => import("/app/pages/home-feed.vue").then(m => m.default || m)
  },
  {
    name: indexN6pT4Un8hYMeta?.name ?? "index___en",
    path: indexN6pT4Un8hYMeta?.path ?? "/",
    meta: indexN6pT4Un8hYMeta || {},
    alias: indexN6pT4Un8hYMeta?.alias || [],
    redirect: indexN6pT4Un8hYMeta?.redirect,
    component: () => import("/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: landingrvSETkGqnoMeta?.name ?? "landing___en",
    path: landingrvSETkGqnoMeta?.path ?? "/landing",
    meta: landingrvSETkGqnoMeta || {},
    alias: landingrvSETkGqnoMeta?.alias || [],
    redirect: landingrvSETkGqnoMeta?.redirect,
    component: () => import("/app/pages/landing.vue").then(m => m.default || m)
  },
  {
    name: _91id_93CdhVbPiRnyMeta?.name ?? "live-stream-id___en",
    path: _91id_93CdhVbPiRnyMeta?.path ?? "/live-stream/:id()",
    meta: _91id_93CdhVbPiRnyMeta || {},
    alias: _91id_93CdhVbPiRnyMeta?.alias || [],
    redirect: _91id_93CdhVbPiRnyMeta?.redirect,
    component: () => import("/app/pages/live-stream/[id].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93j2gXefvOK9Meta?.name ?? "ls-slug___en",
    path: _91_46_46_46slug_93j2gXefvOK9Meta?.path ?? "/ls/:slug(.*)*",
    meta: _91_46_46_46slug_93j2gXefvOK9Meta || {},
    alias: _91_46_46_46slug_93j2gXefvOK9Meta?.alias || [],
    redirect: _91_46_46_46slug_93j2gXefvOK9Meta?.redirect,
    component: () => import("/app/pages/ls/[...slug].vue").then(m => m.default || m)
  },
  {
    name: market_45statusZKrLrP0NR0Meta?.name ?? "market-status___en",
    path: market_45statusZKrLrP0NR0Meta?.path ?? "/market-status",
    meta: market_45statusZKrLrP0NR0Meta || {},
    alias: market_45statusZKrLrP0NR0Meta?.alias || [],
    redirect: market_45statusZKrLrP0NR0Meta?.redirect,
    component: () => import("/app/pages/market-status.vue").then(m => m.default || m)
  },
  {
    name: news_45apiq3FNbV8QcyMeta?.name ?? "news-api___en",
    path: news_45apiq3FNbV8QcyMeta?.path ?? "/news-api",
    meta: news_45apiq3FNbV8QcyMeta || {},
    alias: news_45apiq3FNbV8QcyMeta?.alias || [],
    redirect: news_45apiq3FNbV8QcyMeta?.redirect,
    component: () => import("/app/pages/news-api.vue").then(m => m.default || m)
  },
  {
    name: indexKaVKCQDUK5Meta?.name ?? "news-feed___en",
    path: indexKaVKCQDUK5Meta?.path ?? "/news-feed",
    meta: indexKaVKCQDUK5Meta || {},
    alias: indexKaVKCQDUK5Meta?.alias || [],
    redirect: indexKaVKCQDUK5Meta?.redirect,
    component: () => import("/app/pages/news-feed/index.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93h6ecm9GkHAMeta?.name ?? "news-slug___en",
    path: _91slug_93h6ecm9GkHAMeta?.path ?? "/news/:slug()",
    meta: _91slug_93h6ecm9GkHAMeta || {},
    alias: _91slug_93h6ecm9GkHAMeta?.alias || [],
    redirect: _91slug_93h6ecm9GkHAMeta?.redirect,
    component: () => import("/app/pages/news/[slug].vue").then(m => m.default || m)
  },
  {
    name: editPukIXt8PypMeta?.name ?? "post-slug-edit___en",
    path: editPukIXt8PypMeta?.path ?? "/post/:slug()/edit",
    meta: editPukIXt8PypMeta || {},
    alias: editPukIXt8PypMeta?.alias || [],
    redirect: editPukIXt8PypMeta?.redirect,
    component: () => import("/app/pages/post/[slug]/edit.vue").then(m => m.default || m)
  },
  {
    name: index9TeG384RYsMeta?.name ?? "post-slug___en",
    path: index9TeG384RYsMeta?.path ?? "/post/:slug()",
    meta: index9TeG384RYsMeta || {},
    alias: index9TeG384RYsMeta?.alias || [],
    redirect: index9TeG384RYsMeta?.redirect,
    component: () => import("/app/pages/post/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: privacy_45noticegeuSyXysWNMeta?.name ?? "privacy-notice___en",
    path: privacy_45noticegeuSyXysWNMeta?.path ?? "/privacy-notice",
    meta: privacy_45noticegeuSyXysWNMeta || {},
    alias: privacy_45noticegeuSyXysWNMeta?.alias || [],
    redirect: privacy_45noticegeuSyXysWNMeta?.redirect,
    component: () => import("/app/pages/privacy-notice.vue").then(m => m.default || m)
  },
  {
    name: _91id_93irVUbxHuAaMeta?.name ?? "project-library-id___en",
    path: _91id_93irVUbxHuAaMeta?.path ?? "/project-library/:id()",
    meta: _91id_93irVUbxHuAaMeta || {},
    alias: _91id_93irVUbxHuAaMeta?.alias || [],
    redirect: _91id_93irVUbxHuAaMeta?.redirect,
    component: () => import("/app/pages/project-library/[id].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93UgNoDN7oUoMeta?.name ?? "project-slug___en",
    path: _91slug_93UgNoDN7oUoMeta?.path ?? "/project/:slug()",
    meta: _91slug_93UgNoDN7oUoMeta || {},
    alias: _91slug_93UgNoDN7oUoMeta?.alias || [],
    redirect: _91slug_93UgNoDN7oUoMeta?.redirect,
    component: () => import("/app/pages/project/[slug].vue").then(m => m.default || m)
  },
  {
    name: searchfcnTL5fl5XMeta?.name ?? "search___en",
    path: searchfcnTL5fl5XMeta?.path ?? "/search",
    meta: searchfcnTL5fl5XMeta || {},
    alias: searchfcnTL5fl5XMeta?.alias || [],
    redirect: searchfcnTL5fl5XMeta?.redirect,
    component: () => import("/app/pages/search.vue").then(m => m.default || m)
  },
  {
    name: technical_45feedbackuTtUMc8w0OMeta?.name ?? "technical-feedback___en",
    path: technical_45feedbackuTtUMc8w0OMeta?.path ?? "/technical-feedback",
    meta: technical_45feedbackuTtUMc8w0OMeta || {},
    alias: technical_45feedbackuTtUMc8w0OMeta?.alias || [],
    redirect: technical_45feedbackuTtUMc8w0OMeta?.redirect,
    component: () => import("/app/pages/technical-feedback.vue").then(m => m.default || m)
  },
  {
    name: terms_45of_45useA3Owpnmw23Meta?.name ?? "terms-of-use___en",
    path: terms_45of_45useA3Owpnmw23Meta?.path ?? "/terms-of-use",
    meta: terms_45of_45useA3Owpnmw23Meta || {},
    alias: terms_45of_45useA3Owpnmw23Meta?.alias || [],
    redirect: terms_45of_45useA3Owpnmw23Meta?.redirect,
    component: () => import("/app/pages/terms-of-use.vue").then(m => m.default || m)
  },
  {
    name: _91id_93qjicQeGjhhMeta?.name ?? "token-id___en",
    path: _91id_93qjicQeGjhhMeta?.path ?? "/token/:id()",
    meta: _91id_93qjicQeGjhhMeta || {},
    alias: _91id_93qjicQeGjhhMeta?.alias || [],
    redirect: _91id_93qjicQeGjhhMeta?.redirect,
    component: () => import("/app/pages/token/[id].vue").then(m => m.default || m)
  },
  {
    name: unsubscribeuH6r0XUkMkMeta?.name ?? "unsubscribe___en",
    path: unsubscribeuH6r0XUkMkMeta?.path ?? "/unsubscribe",
    meta: unsubscribeuH6r0XUkMkMeta || {},
    alias: unsubscribeuH6r0XUkMkMeta?.alias || [],
    redirect: unsubscribeuH6r0XUkMkMeta?.redirect,
    component: () => import("/app/pages/unsubscribe.vue").then(m => m.default || m)
  },
  {
    name: _91id_93RVBZU44zsvMeta?.name ?? "user-id___en",
    path: _91id_93RVBZU44zsvMeta?.path ?? "/user/:id()",
    meta: _91id_93RVBZU44zsvMeta || {},
    alias: _91id_93RVBZU44zsvMeta?.alias || [],
    redirect: _91id_93RVBZU44zsvMeta?.redirect,
    component: () => import("/app/pages/user/[id].vue").then(m => m.default || m)
  },
  {
    name: articlesT9jr87VNjzMeta?.name ?? "user-articles___en",
    path: articlesT9jr87VNjzMeta?.path ?? "/user/articles",
    meta: articlesT9jr87VNjzMeta || {},
    alias: articlesT9jr87VNjzMeta?.alias || [],
    redirect: articlesT9jr87VNjzMeta?.redirect,
    component: () => import("/app/pages/user/articles.vue").then(m => m.default || m)
  },
  {
    name: blocked_45usersXLQATV4oF1Meta?.name ?? "user-blocked-users___en",
    path: blocked_45usersXLQATV4oF1Meta?.path ?? "/user/blocked-users",
    meta: blocked_45usersXLQATV4oF1Meta || {},
    alias: blocked_45usersXLQATV4oF1Meta?.alias || [],
    redirect: blocked_45usersXLQATV4oF1Meta?.redirect,
    component: () => import("/app/pages/user/blocked-users.vue").then(m => m.default || m)
  },
  {
    name: bookmarksUeWKsfEu4ZMeta?.name ?? "user-bookmarks___en",
    path: bookmarksUeWKsfEu4ZMeta?.path ?? "/user/bookmarks",
    meta: bookmarksUeWKsfEu4ZMeta || {},
    alias: bookmarksUeWKsfEu4ZMeta?.alias || [],
    redirect: bookmarksUeWKsfEu4ZMeta?.redirect,
    component: () => import("/app/pages/user/bookmarks.vue").then(m => m.default || m)
  },
  {
    name: communitiesn9ehayBcnwMeta?.name ?? "user-communities___en",
    path: communitiesn9ehayBcnwMeta?.path ?? "/user/communities",
    meta: communitiesn9ehayBcnwMeta || {},
    alias: communitiesn9ehayBcnwMeta?.alias || [],
    redirect: communitiesn9ehayBcnwMeta?.redirect,
    component: () => import("/app/pages/user/communities.vue").then(m => m.default || m)
  },
  {
    name: _91hash_93iKTLTIW3ZEMeta?.name ?? "user-complete-hash___en",
    path: _91hash_93iKTLTIW3ZEMeta?.path ?? "/user/complete/:hash()",
    meta: _91hash_93iKTLTIW3ZEMeta || {},
    alias: _91hash_93iKTLTIW3ZEMeta?.alias || [],
    redirect: _91hash_93iKTLTIW3ZEMeta?.redirect,
    component: () => import("/app/pages/user/complete/[hash].vue").then(m => m.default || m)
  },
  {
    name: create_45articlezADS7UwfPwMeta?.name ?? "user-create-article___en",
    path: create_45articlezADS7UwfPwMeta?.path ?? "/user/create-article",
    meta: create_45articlezADS7UwfPwMeta || {},
    alias: create_45articlezADS7UwfPwMeta?.alias || [],
    redirect: create_45articlezADS7UwfPwMeta?.redirect,
    component: () => import("/app/pages/user/create-article.vue").then(m => m.default || m)
  },
  {
    name: create_45communityK8yDdsrKXKMeta?.name ?? "user-create-community___en",
    path: create_45communityK8yDdsrKXKMeta?.path ?? "/user/create-community",
    meta: create_45communityK8yDdsrKXKMeta || {},
    alias: create_45communityK8yDdsrKXKMeta?.alias || [],
    redirect: create_45communityK8yDdsrKXKMeta?.redirect,
    component: () => import("/app/pages/user/create-community.vue").then(m => m.default || m)
  },
  {
    name: create_45eventD8BbloxvDuMeta?.name ?? "user-create-event___en",
    path: create_45eventD8BbloxvDuMeta?.path ?? "/user/create-event",
    meta: create_45eventD8BbloxvDuMeta || {},
    alias: create_45eventD8BbloxvDuMeta?.alias || [],
    redirect: create_45eventD8BbloxvDuMeta?.redirect,
    component: () => import("/app/pages/user/create-event.vue").then(m => m.default || m)
  },
  {
    name: create_45projecti0Kmc80IgWMeta?.name ?? "user-create-project___en",
    path: create_45projecti0Kmc80IgWMeta?.path ?? "/user/create-project",
    meta: create_45projecti0Kmc80IgWMeta || {},
    alias: create_45projecti0Kmc80IgWMeta?.alias || [],
    redirect: create_45projecti0Kmc80IgWMeta?.redirect,
    component: () => import("/app/pages/user/create-project.vue").then(m => m.default || m)
  },
  {
    name: create_45tokenj0JJBsmmjsMeta?.name ?? "user-create-token___en",
    path: create_45tokenj0JJBsmmjsMeta?.path ?? "/user/create-token",
    meta: create_45tokenj0JJBsmmjsMeta || {},
    alias: create_45tokenj0JJBsmmjsMeta?.alias || [],
    redirect: create_45tokenj0JJBsmmjsMeta?.redirect,
    component: () => import("/app/pages/user/create-token.vue").then(m => m.default || m)
  },
  {
    name: _91slug_9376w3MP4yhHMeta?.name ?? "user-edit-article-slug___en",
    path: _91slug_9376w3MP4yhHMeta?.path ?? "/user/edit-article/:slug()",
    meta: _91slug_9376w3MP4yhHMeta || {},
    alias: _91slug_9376w3MP4yhHMeta?.alias || [],
    redirect: _91slug_9376w3MP4yhHMeta?.redirect,
    component: () => import("/app/pages/user/edit-article/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91id_93wLvbN9BZy8Meta?.name ?? "user-edit-community-id___en",
    path: _91id_93wLvbN9BZy8Meta?.path ?? "/user/edit-community/:id()",
    meta: _91id_93wLvbN9BZy8Meta || {},
    alias: _91id_93wLvbN9BZy8Meta?.alias || [],
    redirect: _91id_93wLvbN9BZy8Meta?.redirect,
    component: () => import("/app/pages/user/edit-community/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93Kd4HGOJgVnMeta?.name ?? "user-edit-event-id___en",
    path: _91id_93Kd4HGOJgVnMeta?.path ?? "/user/edit-event/:id()",
    meta: _91id_93Kd4HGOJgVnMeta || {},
    alias: _91id_93Kd4HGOJgVnMeta?.alias || [],
    redirect: _91id_93Kd4HGOJgVnMeta?.redirect,
    component: () => import("/app/pages/user/edit-event/[id].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93C5CtYd2TPaMeta?.name ?? "user-edit-project-slug___en",
    path: _91slug_93C5CtYd2TPaMeta?.path ?? "/user/edit-project/:slug()",
    meta: _91slug_93C5CtYd2TPaMeta || {},
    alias: _91slug_93C5CtYd2TPaMeta?.alias || [],
    redirect: _91slug_93C5CtYd2TPaMeta?.redirect,
    component: () => import("/app/pages/user/edit-project/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91id_939pEgEkG1yyMeta?.name ?? "user-edit-token-id___en",
    path: _91id_939pEgEkG1yyMeta?.path ?? "/user/edit-token/:id()",
    meta: _91id_939pEgEkG1yyMeta || {},
    alias: _91id_939pEgEkG1yyMeta?.alias || [],
    redirect: _91id_939pEgEkG1yyMeta?.redirect,
    component: () => import("/app/pages/user/edit-token/[id].vue").then(m => m.default || m)
  },
  {
    name: eventsiTmbXtGfOLMeta?.name ?? "user-events___en",
    path: eventsiTmbXtGfOLMeta?.path ?? "/user/events",
    meta: eventsiTmbXtGfOLMeta || {},
    alias: eventsiTmbXtGfOLMeta?.alias || [],
    redirect: eventsiTmbXtGfOLMeta?.redirect,
    component: () => import("/app/pages/user/events.vue").then(m => m.default || m)
  },
  {
    name: loginR70WB70wCiMeta?.name ?? "user-login___en",
    path: loginR70WB70wCiMeta?.path ?? "/user/login",
    meta: loginR70WB70wCiMeta || {},
    alias: loginR70WB70wCiMeta?.alias || [],
    redirect: loginR70WB70wCiMeta?.redirect,
    component: () => import("/app/pages/user/login.vue").then(m => m.default || m)
  },
  {
    name: logoutaviNfKdBL2Meta?.name ?? "user-logout___en",
    path: logoutaviNfKdBL2Meta?.path ?? "/user/logout",
    meta: logoutaviNfKdBL2Meta || {},
    alias: logoutaviNfKdBL2Meta?.alias || [],
    redirect: logoutaviNfKdBL2Meta?.redirect,
    component: () => import("/app/pages/user/logout.vue").then(m => m.default || m)
  },
  {
    name: _91hash_93vajZBYv9lQMeta?.name ?? "user-new-user-hash___en",
    path: _91hash_93vajZBYv9lQMeta?.path ?? "/user/new-user/:hash()",
    meta: _91hash_93vajZBYv9lQMeta || {},
    alias: _91hash_93vajZBYv9lQMeta?.alias || [],
    redirect: _91hash_93vajZBYv9lQMeta?.redirect,
    component: () => import("/app/pages/user/new-user/[hash].vue").then(m => m.default || m)
  },
  {
    name: passwordckK2hF554oMeta?.name ?? "user-password___en",
    path: passwordckK2hF554oMeta?.path ?? "/user/password",
    meta: passwordckK2hF554oMeta || {},
    alias: passwordckK2hF554oMeta?.alias || [],
    redirect: passwordckK2hF554oMeta?.redirect,
    component: () => import("/app/pages/user/password.vue").then(m => m.default || m)
  },
  {
    name: postsJ4g2Ow1JvzMeta?.name ?? "user-posts___en",
    path: postsJ4g2Ow1JvzMeta?.path ?? "/user/posts",
    meta: postsJ4g2Ow1JvzMeta || {},
    alias: postsJ4g2Ow1JvzMeta?.alias || [],
    redirect: postsJ4g2Ow1JvzMeta?.redirect,
    component: () => import("/app/pages/user/posts.vue").then(m => m.default || m)
  },
  {
    name: profileWu1o0nyKd6Meta?.name ?? "user-profile___en",
    path: profileWu1o0nyKd6Meta?.path ?? "/user/profile",
    meta: profileWu1o0nyKd6Meta || {},
    alias: profileWu1o0nyKd6Meta?.alias || [],
    redirect: profileWu1o0nyKd6Meta?.redirect,
    component: () => import("/app/pages/user/profile.vue").then(m => m.default || m)
  },
  {
    name: projectsjG0tSCfZ73Meta?.name ?? "user-projects___en",
    path: projectsjG0tSCfZ73Meta?.path ?? "/user/projects",
    meta: projectsjG0tSCfZ73Meta || {},
    alias: projectsjG0tSCfZ73Meta?.alias || [],
    redirect: projectsjG0tSCfZ73Meta?.redirect,
    component: () => import("/app/pages/user/projects.vue").then(m => m.default || m)
  },
  {
    name: register4GabFBgyAsMeta?.name ?? "user-register___en",
    path: register4GabFBgyAsMeta?.path ?? "/user/register",
    meta: register4GabFBgyAsMeta || {},
    alias: register4GabFBgyAsMeta?.alias || [],
    redirect: register4GabFBgyAsMeta?.redirect,
    component: () => import("/app/pages/user/register.vue").then(m => m.default || m)
  },
  {
    name: _91hash_93s6gk9zTWnnMeta?.name ?? "user-reset-hash___en",
    path: _91hash_93s6gk9zTWnnMeta?.path ?? "/user/reset/:hash()",
    meta: _91hash_93s6gk9zTWnnMeta || {},
    alias: _91hash_93s6gk9zTWnnMeta?.alias || [],
    redirect: _91hash_93s6gk9zTWnnMeta?.redirect,
    component: () => import("/app/pages/user/reset/[hash].vue").then(m => m.default || m)
  },
  {
    name: settingsBeRaS6K39rMeta?.name ?? "user-settings___en",
    path: settingsBeRaS6K39rMeta?.path ?? "/user/settings",
    meta: settingsBeRaS6K39rMeta || {},
    alias: settingsBeRaS6K39rMeta?.alias || [],
    redirect: settingsBeRaS6K39rMeta?.redirect,
    component: () => import("/app/pages/user/settings.vue").then(m => m.default || m)
  },
  {
    name: tokensZp1Bo0nBuJMeta?.name ?? "user-tokens___en",
    path: tokensZp1Bo0nBuJMeta?.path ?? "/user/tokens",
    meta: tokensZp1Bo0nBuJMeta || {},
    alias: tokensZp1Bo0nBuJMeta?.alias || [],
    redirect: tokensZp1Bo0nBuJMeta?.redirect,
    component: () => import("/app/pages/user/tokens.vue").then(m => m.default || m)
  },
  {
    name: dappsPfPNbm6i9SMeta?.name ?? "wiki-dapps___en",
    path: dappsPfPNbm6i9SMeta?.path ?? "/wiki/dapps",
    meta: dappsPfPNbm6i9SMeta || {},
    alias: dappsPfPNbm6i9SMeta?.alias || [],
    redirect: dappsPfPNbm6i9SMeta?.redirect,
    component: () => import("/app/pages/wiki/dapps.vue").then(m => m.default || m)
  },
  {
    name: indexgfqNjlqG7TMeta?.name ?? "wiki___en",
    path: indexgfqNjlqG7TMeta?.path ?? "/wiki",
    meta: indexgfqNjlqG7TMeta || {},
    alias: indexgfqNjlqG7TMeta?.alias || [],
    redirect: indexgfqNjlqG7TMeta?.redirect,
    component: () => import("/app/pages/wiki/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93ERSjn3Z7LcMeta?.name ?? "wiki-roadmap-id___en",
    path: _91id_93ERSjn3Z7LcMeta?.path ?? "/wiki/roadmap/:id()",
    meta: _91id_93ERSjn3Z7LcMeta || {},
    alias: _91id_93ERSjn3Z7LcMeta?.alias || [],
    redirect: _91id_93ERSjn3Z7LcMeta?.redirect,
    component: () => import("/app/pages/wiki/roadmap/[id].vue").then(m => m.default || m)
  },
  {
    name: indexq0Y0oMklcrMeta?.name ?? "wiki-roadmap___en",
    path: indexq0Y0oMklcrMeta?.path ?? "/wiki/roadmap",
    meta: indexq0Y0oMklcrMeta || {},
    alias: indexq0Y0oMklcrMeta?.alias || [],
    redirect: indexq0Y0oMklcrMeta?.redirect,
    component: () => import("/app/pages/wiki/roadmap/index.vue").then(m => m.default || m)
  },
  {
    name: _91term_93CazlAnrlhVMeta?.name ?? "wiki-vocabulary-letter-term___en",
    path: _91term_93CazlAnrlhVMeta?.path ?? "/wiki/vocabulary/:letter()/:term()",
    meta: _91term_93CazlAnrlhVMeta || {},
    alias: _91term_93CazlAnrlhVMeta?.alias || [],
    redirect: _91term_93CazlAnrlhVMeta?.redirect,
    component: () => import("/app/pages/wiki/vocabulary/[letter]/[term].vue").then(m => m.default || m)
  },
  {
    name: indexzm16WkKjLWMeta?.name ?? "wiki-vocabulary-letter___en",
    path: indexzm16WkKjLWMeta?.path ?? "/wiki/vocabulary/:letter()",
    meta: indexzm16WkKjLWMeta || {},
    alias: indexzm16WkKjLWMeta?.alias || [],
    redirect: indexzm16WkKjLWMeta?.redirect,
    component: () => import("/app/pages/wiki/vocabulary/[letter]/index.vue").then(m => m.default || m)
  },
  {
    name: nuxt_643_4610_463__64parcel_43watcher_642_464_461__64types_43node_6420_4611_4624_encoding_640_461_4613_eslint_648_4657bWx3yCoGtnMeta?.name ?? undefined,
    path: nuxt_643_4610_463__64parcel_43watcher_642_464_461__64types_43node_6420_4611_4624_encoding_640_461_4613_eslint_648_4657bWx3yCoGtnMeta?.path ?? "/cardano-summit-day-one",
    meta: nuxt_643_4610_463__64parcel_43watcher_642_464_461__64types_43node_6420_4611_4624_encoding_640_461_4613_eslint_648_4657bWx3yCoGtnMeta || {},
    alias: nuxt_643_4610_463__64parcel_43watcher_642_464_461__64types_43node_6420_4611_4624_encoding_640_461_4613_eslint_648_4657bWx3yCoGtnMeta?.alias || [],
    redirect: nuxt_643_4610_463__64parcel_43watcher_642_464_461__64types_43node_6420_4611_4624_encoding_640_461_4613_eslint_648_4657bWx3yCoGtnMeta?.redirect,
    component: () => import("/app/node_modules/.pnpm/nuxt@3.10.3_@parcel+watcher@2.4.1_@types+node@20.11.24_encoding@0.1.13_eslint@8.57.0_op_7389cdbc4aa1eccdb186f846b074abf7/node_modules/nuxt/dist/pages/runtime/component-stub").then(m => m.default || m)
  },
  {
    name: nuxt_643_4610_463__64parcel_43watcher_642_464_461__64types_43node_6420_4611_4624_encoding_640_461_4613_eslint_648_4657bWx3yCoGtnMeta?.name ?? undefined,
    path: nuxt_643_4610_463__64parcel_43watcher_642_464_461__64types_43node_6420_4611_4624_encoding_640_461_4613_eslint_648_4657bWx3yCoGtnMeta?.path ?? "/cardano-summit-day-two",
    meta: nuxt_643_4610_463__64parcel_43watcher_642_464_461__64types_43node_6420_4611_4624_encoding_640_461_4613_eslint_648_4657bWx3yCoGtnMeta || {},
    alias: nuxt_643_4610_463__64parcel_43watcher_642_464_461__64types_43node_6420_4611_4624_encoding_640_461_4613_eslint_648_4657bWx3yCoGtnMeta?.alias || [],
    redirect: nuxt_643_4610_463__64parcel_43watcher_642_464_461__64types_43node_6420_4611_4624_encoding_640_461_4613_eslint_648_4657bWx3yCoGtnMeta?.redirect,
    component: () => import("/app/node_modules/.pnpm/nuxt@3.10.3_@parcel+watcher@2.4.1_@types+node@20.11.24_encoding@0.1.13_eslint@8.57.0_op_7389cdbc4aa1eccdb186f846b074abf7/node_modules/nuxt/dist/pages/runtime/component-stub").then(m => m.default || m)
  },
  {
    name: nuxt_643_4610_463__64parcel_43watcher_642_464_461__64types_43node_6420_4611_4624_encoding_640_461_4613_eslint_648_4657bWx3yCoGtnMeta?.name ?? undefined,
    path: nuxt_643_4610_463__64parcel_43watcher_642_464_461__64types_43node_6420_4611_4624_encoding_640_461_4613_eslint_648_4657bWx3yCoGtnMeta?.path ?? "/cardanoindonesia",
    meta: nuxt_643_4610_463__64parcel_43watcher_642_464_461__64types_43node_6420_4611_4624_encoding_640_461_4613_eslint_648_4657bWx3yCoGtnMeta || {},
    alias: nuxt_643_4610_463__64parcel_43watcher_642_464_461__64types_43node_6420_4611_4624_encoding_640_461_4613_eslint_648_4657bWx3yCoGtnMeta?.alias || [],
    redirect: nuxt_643_4610_463__64parcel_43watcher_642_464_461__64types_43node_6420_4611_4624_encoding_640_461_4613_eslint_648_4657bWx3yCoGtnMeta?.redirect,
    component: () => import("/app/node_modules/.pnpm/nuxt@3.10.3_@parcel+watcher@2.4.1_@types+node@20.11.24_encoding@0.1.13_eslint@8.57.0_op_7389cdbc4aa1eccdb186f846b074abf7/node_modules/nuxt/dist/pages/runtime/component-stub").then(m => m.default || m)
  },
  {
    name: nuxt_643_4610_463__64parcel_43watcher_642_464_461__64types_43node_6420_4611_4624_encoding_640_461_4613_eslint_648_4657bWx3yCoGtnMeta?.name ?? undefined,
    path: nuxt_643_4610_463__64parcel_43watcher_642_464_461__64types_43node_6420_4611_4624_encoding_640_461_4613_eslint_648_4657bWx3yCoGtnMeta?.path ?? "/livestream-december-4",
    meta: nuxt_643_4610_463__64parcel_43watcher_642_464_461__64types_43node_6420_4611_4624_encoding_640_461_4613_eslint_648_4657bWx3yCoGtnMeta || {},
    alias: nuxt_643_4610_463__64parcel_43watcher_642_464_461__64types_43node_6420_4611_4624_encoding_640_461_4613_eslint_648_4657bWx3yCoGtnMeta?.alias || [],
    redirect: nuxt_643_4610_463__64parcel_43watcher_642_464_461__64types_43node_6420_4611_4624_encoding_640_461_4613_eslint_648_4657bWx3yCoGtnMeta?.redirect,
    component: () => import("/app/node_modules/.pnpm/nuxt@3.10.3_@parcel+watcher@2.4.1_@types+node@20.11.24_encoding@0.1.13_eslint@8.57.0_op_7389cdbc4aa1eccdb186f846b074abf7/node_modules/nuxt/dist/pages/runtime/component-stub").then(m => m.default || m)
  },
  {
    name: nuxt_643_4610_463__64parcel_43watcher_642_464_461__64types_43node_6420_4611_4624_encoding_640_461_4613_eslint_648_4657bWx3yCoGtnMeta?.name ?? undefined,
    path: nuxt_643_4610_463__64parcel_43watcher_642_464_461__64types_43node_6420_4611_4624_encoding_640_461_4613_eslint_648_4657bWx3yCoGtnMeta?.path ?? "/livestream-december-5",
    meta: nuxt_643_4610_463__64parcel_43watcher_642_464_461__64types_43node_6420_4611_4624_encoding_640_461_4613_eslint_648_4657bWx3yCoGtnMeta || {},
    alias: nuxt_643_4610_463__64parcel_43watcher_642_464_461__64types_43node_6420_4611_4624_encoding_640_461_4613_eslint_648_4657bWx3yCoGtnMeta?.alias || [],
    redirect: nuxt_643_4610_463__64parcel_43watcher_642_464_461__64types_43node_6420_4611_4624_encoding_640_461_4613_eslint_648_4657bWx3yCoGtnMeta?.redirect,
    component: () => import("/app/node_modules/.pnpm/nuxt@3.10.3_@parcel+watcher@2.4.1_@types+node@20.11.24_encoding@0.1.13_eslint@8.57.0_op_7389cdbc4aa1eccdb186f846b074abf7/node_modules/nuxt/dist/pages/runtime/component-stub").then(m => m.default || m)
  },
  {
    name: nuxt_643_4610_463__64parcel_43watcher_642_464_461__64types_43node_6420_4611_4624_encoding_640_461_4613_eslint_648_4657bWx3yCoGtnMeta?.name ?? undefined,
    path: nuxt_643_4610_463__64parcel_43watcher_642_464_461__64types_43node_6420_4611_4624_encoding_640_461_4613_eslint_648_4657bWx3yCoGtnMeta?.path ?? "/livestream-december-7",
    meta: nuxt_643_4610_463__64parcel_43watcher_642_464_461__64types_43node_6420_4611_4624_encoding_640_461_4613_eslint_648_4657bWx3yCoGtnMeta || {},
    alias: nuxt_643_4610_463__64parcel_43watcher_642_464_461__64types_43node_6420_4611_4624_encoding_640_461_4613_eslint_648_4657bWx3yCoGtnMeta?.alias || [],
    redirect: nuxt_643_4610_463__64parcel_43watcher_642_464_461__64types_43node_6420_4611_4624_encoding_640_461_4613_eslint_648_4657bWx3yCoGtnMeta?.redirect,
    component: () => import("/app/node_modules/.pnpm/nuxt@3.10.3_@parcel+watcher@2.4.1_@types+node@20.11.24_encoding@0.1.13_eslint@8.57.0_op_7389cdbc4aa1eccdb186f846b074abf7/node_modules/nuxt/dist/pages/runtime/component-stub").then(m => m.default || m)
  },
  {
    name: nuxt_643_4610_463__64parcel_43watcher_642_464_461__64types_43node_6420_4611_4624_encoding_640_461_4613_eslint_648_4657bWx3yCoGtnMeta?.name ?? undefined,
    path: nuxt_643_4610_463__64parcel_43watcher_642_464_461__64types_43node_6420_4611_4624_encoding_640_461_4613_eslint_648_4657bWx3yCoGtnMeta?.path ?? "/livestream-december-8",
    meta: nuxt_643_4610_463__64parcel_43watcher_642_464_461__64types_43node_6420_4611_4624_encoding_640_461_4613_eslint_648_4657bWx3yCoGtnMeta || {},
    alias: nuxt_643_4610_463__64parcel_43watcher_642_464_461__64types_43node_6420_4611_4624_encoding_640_461_4613_eslint_648_4657bWx3yCoGtnMeta?.alias || [],
    redirect: nuxt_643_4610_463__64parcel_43watcher_642_464_461__64types_43node_6420_4611_4624_encoding_640_461_4613_eslint_648_4657bWx3yCoGtnMeta?.redirect,
    component: () => import("/app/node_modules/.pnpm/nuxt@3.10.3_@parcel+watcher@2.4.1_@types+node@20.11.24_encoding@0.1.13_eslint@8.57.0_op_7389cdbc4aa1eccdb186f846b074abf7/node_modules/nuxt/dist/pages/runtime/component-stub").then(m => m.default || m)
  },
  {
    name: nuxt_643_4610_463__64parcel_43watcher_642_464_461__64types_43node_6420_4611_4624_encoding_640_461_4613_eslint_648_4657bWx3yCoGtnMeta?.name ?? undefined,
    path: nuxt_643_4610_463__64parcel_43watcher_642_464_461__64types_43node_6420_4611_4624_encoding_640_461_4613_eslint_648_4657bWx3yCoGtnMeta?.path ?? "/bit2me",
    meta: nuxt_643_4610_463__64parcel_43watcher_642_464_461__64types_43node_6420_4611_4624_encoding_640_461_4613_eslint_648_4657bWx3yCoGtnMeta || {},
    alias: nuxt_643_4610_463__64parcel_43watcher_642_464_461__64types_43node_6420_4611_4624_encoding_640_461_4613_eslint_648_4657bWx3yCoGtnMeta?.alias || [],
    redirect: nuxt_643_4610_463__64parcel_43watcher_642_464_461__64types_43node_6420_4611_4624_encoding_640_461_4613_eslint_648_4657bWx3yCoGtnMeta?.redirect,
    component: () => import("/app/node_modules/.pnpm/nuxt@3.10.3_@parcel+watcher@2.4.1_@types+node@20.11.24_encoding@0.1.13_eslint@8.57.0_op_7389cdbc4aa1eccdb186f846b074abf7/node_modules/nuxt/dist/pages/runtime/component-stub").then(m => m.default || m)
  },
  {
    name: nuxt_643_4610_463__64parcel_43watcher_642_464_461__64types_43node_6420_4611_4624_encoding_640_461_4613_eslint_648_4657bWx3yCoGtnMeta?.name ?? undefined,
    path: nuxt_643_4610_463__64parcel_43watcher_642_464_461__64types_43node_6420_4611_4624_encoding_640_461_4613_eslint_648_4657bWx3yCoGtnMeta?.path ?? "/stream-cardanocodex-16",
    meta: nuxt_643_4610_463__64parcel_43watcher_642_464_461__64types_43node_6420_4611_4624_encoding_640_461_4613_eslint_648_4657bWx3yCoGtnMeta || {},
    alias: nuxt_643_4610_463__64parcel_43watcher_642_464_461__64types_43node_6420_4611_4624_encoding_640_461_4613_eslint_648_4657bWx3yCoGtnMeta?.alias || [],
    redirect: nuxt_643_4610_463__64parcel_43watcher_642_464_461__64types_43node_6420_4611_4624_encoding_640_461_4613_eslint_648_4657bWx3yCoGtnMeta?.redirect,
    component: () => import("/app/node_modules/.pnpm/nuxt@3.10.3_@parcel+watcher@2.4.1_@types+node@20.11.24_encoding@0.1.13_eslint@8.57.0_op_7389cdbc4aa1eccdb186f846b074abf7/node_modules/nuxt/dist/pages/runtime/component-stub").then(m => m.default || m)
  },
  {
    name: nuxt_643_4610_463__64parcel_43watcher_642_464_461__64types_43node_6420_4611_4624_encoding_640_461_4613_eslint_648_4657bWx3yCoGtnMeta?.name ?? undefined,
    path: nuxt_643_4610_463__64parcel_43watcher_642_464_461__64types_43node_6420_4611_4624_encoding_640_461_4613_eslint_648_4657bWx3yCoGtnMeta?.path ?? "/stream-cardanocodex-18",
    meta: nuxt_643_4610_463__64parcel_43watcher_642_464_461__64types_43node_6420_4611_4624_encoding_640_461_4613_eslint_648_4657bWx3yCoGtnMeta || {},
    alias: nuxt_643_4610_463__64parcel_43watcher_642_464_461__64types_43node_6420_4611_4624_encoding_640_461_4613_eslint_648_4657bWx3yCoGtnMeta?.alias || [],
    redirect: nuxt_643_4610_463__64parcel_43watcher_642_464_461__64types_43node_6420_4611_4624_encoding_640_461_4613_eslint_648_4657bWx3yCoGtnMeta?.redirect,
    component: () => import("/app/node_modules/.pnpm/nuxt@3.10.3_@parcel+watcher@2.4.1_@types+node@20.11.24_encoding@0.1.13_eslint@8.57.0_op_7389cdbc4aa1eccdb186f846b074abf7/node_modules/nuxt/dist/pages/runtime/component-stub").then(m => m.default || m)
  },
  {
    name: nuxt_643_4610_463__64parcel_43watcher_642_464_461__64types_43node_6420_4611_4624_encoding_640_461_4613_eslint_648_4657bWx3yCoGtnMeta?.name ?? undefined,
    path: nuxt_643_4610_463__64parcel_43watcher_642_464_461__64types_43node_6420_4611_4624_encoding_640_461_4613_eslint_648_4657bWx3yCoGtnMeta?.path ?? "/stream-cardanocodex-22",
    meta: nuxt_643_4610_463__64parcel_43watcher_642_464_461__64types_43node_6420_4611_4624_encoding_640_461_4613_eslint_648_4657bWx3yCoGtnMeta || {},
    alias: nuxt_643_4610_463__64parcel_43watcher_642_464_461__64types_43node_6420_4611_4624_encoding_640_461_4613_eslint_648_4657bWx3yCoGtnMeta?.alias || [],
    redirect: nuxt_643_4610_463__64parcel_43watcher_642_464_461__64types_43node_6420_4611_4624_encoding_640_461_4613_eslint_648_4657bWx3yCoGtnMeta?.redirect,
    component: () => import("/app/node_modules/.pnpm/nuxt@3.10.3_@parcel+watcher@2.4.1_@types+node@20.11.24_encoding@0.1.13_eslint@8.57.0_op_7389cdbc4aa1eccdb186f846b074abf7/node_modules/nuxt/dist/pages/runtime/component-stub").then(m => m.default || m)
  },
  {
    name: nuxt_643_4610_463__64parcel_43watcher_642_464_461__64types_43node_6420_4611_4624_encoding_640_461_4613_eslint_648_4657bWx3yCoGtnMeta?.name ?? undefined,
    path: nuxt_643_4610_463__64parcel_43watcher_642_464_461__64types_43node_6420_4611_4624_encoding_640_461_4613_eslint_648_4657bWx3yCoGtnMeta?.path ?? "/stream-cardanocodex-25",
    meta: nuxt_643_4610_463__64parcel_43watcher_642_464_461__64types_43node_6420_4611_4624_encoding_640_461_4613_eslint_648_4657bWx3yCoGtnMeta || {},
    alias: nuxt_643_4610_463__64parcel_43watcher_642_464_461__64types_43node_6420_4611_4624_encoding_640_461_4613_eslint_648_4657bWx3yCoGtnMeta?.alias || [],
    redirect: nuxt_643_4610_463__64parcel_43watcher_642_464_461__64types_43node_6420_4611_4624_encoding_640_461_4613_eslint_648_4657bWx3yCoGtnMeta?.redirect,
    component: () => import("/app/node_modules/.pnpm/nuxt@3.10.3_@parcel+watcher@2.4.1_@types+node@20.11.24_encoding@0.1.13_eslint@8.57.0_op_7389cdbc4aa1eccdb186f846b074abf7/node_modules/nuxt/dist/pages/runtime/component-stub").then(m => m.default || m)
  },
  {
    name: nuxt_643_4610_463__64parcel_43watcher_642_464_461__64types_43node_6420_4611_4624_encoding_640_461_4613_eslint_648_4657bWx3yCoGtnMeta?.name ?? undefined,
    path: nuxt_643_4610_463__64parcel_43watcher_642_464_461__64types_43node_6420_4611_4624_encoding_640_461_4613_eslint_648_4657bWx3yCoGtnMeta?.path ?? "/sidan-lab-livestream",
    meta: nuxt_643_4610_463__64parcel_43watcher_642_464_461__64types_43node_6420_4611_4624_encoding_640_461_4613_eslint_648_4657bWx3yCoGtnMeta || {},
    alias: nuxt_643_4610_463__64parcel_43watcher_642_464_461__64types_43node_6420_4611_4624_encoding_640_461_4613_eslint_648_4657bWx3yCoGtnMeta?.alias || [],
    redirect: nuxt_643_4610_463__64parcel_43watcher_642_464_461__64types_43node_6420_4611_4624_encoding_640_461_4613_eslint_648_4657bWx3yCoGtnMeta?.redirect,
    component: () => import("/app/node_modules/.pnpm/nuxt@3.10.3_@parcel+watcher@2.4.1_@types+node@20.11.24_encoding@0.1.13_eslint@8.57.0_op_7389cdbc4aa1eccdb186f846b074abf7/node_modules/nuxt/dist/pages/runtime/component-stub").then(m => m.default || m)
  },
  {
    name: nuxt_643_4610_463__64parcel_43watcher_642_464_461__64types_43node_6420_4611_4624_encoding_640_461_4613_eslint_648_4657bWx3yCoGtnMeta?.name ?? undefined,
    path: nuxt_643_4610_463__64parcel_43watcher_642_464_461__64types_43node_6420_4611_4624_encoding_640_461_4613_eslint_648_4657bWx3yCoGtnMeta?.path ?? "/exploring-adatube-livestream",
    meta: nuxt_643_4610_463__64parcel_43watcher_642_464_461__64types_43node_6420_4611_4624_encoding_640_461_4613_eslint_648_4657bWx3yCoGtnMeta || {},
    alias: nuxt_643_4610_463__64parcel_43watcher_642_464_461__64types_43node_6420_4611_4624_encoding_640_461_4613_eslint_648_4657bWx3yCoGtnMeta?.alias || [],
    redirect: nuxt_643_4610_463__64parcel_43watcher_642_464_461__64types_43node_6420_4611_4624_encoding_640_461_4613_eslint_648_4657bWx3yCoGtnMeta?.redirect,
    component: () => import("/app/node_modules/.pnpm/nuxt@3.10.3_@parcel+watcher@2.4.1_@types+node@20.11.24_encoding@0.1.13_eslint@8.57.0_op_7389cdbc4aa1eccdb186f846b074abf7/node_modules/nuxt/dist/pages/runtime/component-stub").then(m => m.default || m)
  },
  {
    name: nuxt_643_4610_463__64parcel_43watcher_642_464_461__64types_43node_6420_4611_4624_encoding_640_461_4613_eslint_648_4657bWx3yCoGtnMeta?.name ?? undefined,
    path: nuxt_643_4610_463__64parcel_43watcher_642_464_461__64types_43node_6420_4611_4624_encoding_640_461_4613_eslint_648_4657bWx3yCoGtnMeta?.path ?? "/zengate-livestream-22Feb",
    meta: nuxt_643_4610_463__64parcel_43watcher_642_464_461__64types_43node_6420_4611_4624_encoding_640_461_4613_eslint_648_4657bWx3yCoGtnMeta || {},
    alias: nuxt_643_4610_463__64parcel_43watcher_642_464_461__64types_43node_6420_4611_4624_encoding_640_461_4613_eslint_648_4657bWx3yCoGtnMeta?.alias || [],
    redirect: nuxt_643_4610_463__64parcel_43watcher_642_464_461__64types_43node_6420_4611_4624_encoding_640_461_4613_eslint_648_4657bWx3yCoGtnMeta?.redirect,
    component: () => import("/app/node_modules/.pnpm/nuxt@3.10.3_@parcel+watcher@2.4.1_@types+node@20.11.24_encoding@0.1.13_eslint@8.57.0_op_7389cdbc4aa1eccdb186f846b074abf7/node_modules/nuxt/dist/pages/runtime/component-stub").then(m => m.default || m)
  },
  {
    name: nuxt_643_4610_463__64parcel_43watcher_642_464_461__64types_43node_6420_4611_4624_encoding_640_461_4613_eslint_648_4657bWx3yCoGtnMeta?.name ?? undefined,
    path: nuxt_643_4610_463__64parcel_43watcher_642_464_461__64types_43node_6420_4611_4624_encoding_640_461_4613_eslint_648_4657bWx3yCoGtnMeta?.path ?? "/omura-livestream-on-cardano-spot",
    meta: nuxt_643_4610_463__64parcel_43watcher_642_464_461__64types_43node_6420_4611_4624_encoding_640_461_4613_eslint_648_4657bWx3yCoGtnMeta || {},
    alias: nuxt_643_4610_463__64parcel_43watcher_642_464_461__64types_43node_6420_4611_4624_encoding_640_461_4613_eslint_648_4657bWx3yCoGtnMeta?.alias || [],
    redirect: nuxt_643_4610_463__64parcel_43watcher_642_464_461__64types_43node_6420_4611_4624_encoding_640_461_4613_eslint_648_4657bWx3yCoGtnMeta?.redirect,
    component: () => import("/app/node_modules/.pnpm/nuxt@3.10.3_@parcel+watcher@2.4.1_@types+node@20.11.24_encoding@0.1.13_eslint@8.57.0_op_7389cdbc4aa1eccdb186f846b074abf7/node_modules/nuxt/dist/pages/runtime/component-stub").then(m => m.default || m)
  },
  {
    name: nuxt_643_4610_463__64parcel_43watcher_642_464_461__64types_43node_6420_4611_4624_encoding_640_461_4613_eslint_648_4657bWx3yCoGtnMeta?.name ?? undefined,
    path: nuxt_643_4610_463__64parcel_43watcher_642_464_461__64types_43node_6420_4611_4624_encoding_640_461_4613_eslint_648_4657bWx3yCoGtnMeta?.path ?? "/mynth-live-stream-on-cardano-spot",
    meta: nuxt_643_4610_463__64parcel_43watcher_642_464_461__64types_43node_6420_4611_4624_encoding_640_461_4613_eslint_648_4657bWx3yCoGtnMeta || {},
    alias: nuxt_643_4610_463__64parcel_43watcher_642_464_461__64types_43node_6420_4611_4624_encoding_640_461_4613_eslint_648_4657bWx3yCoGtnMeta?.alias || [],
    redirect: nuxt_643_4610_463__64parcel_43watcher_642_464_461__64types_43node_6420_4611_4624_encoding_640_461_4613_eslint_648_4657bWx3yCoGtnMeta?.redirect,
    component: () => import("/app/node_modules/.pnpm/nuxt@3.10.3_@parcel+watcher@2.4.1_@types+node@20.11.24_encoding@0.1.13_eslint@8.57.0_op_7389cdbc4aa1eccdb186f846b074abf7/node_modules/nuxt/dist/pages/runtime/component-stub").then(m => m.default || m)
  },
  {
    name: nuxt_643_4610_463__64parcel_43watcher_642_464_461__64types_43node_6420_4611_4624_encoding_640_461_4613_eslint_648_4657bWx3yCoGtnMeta?.name ?? undefined,
    path: nuxt_643_4610_463__64parcel_43watcher_642_464_461__64types_43node_6420_4611_4624_encoding_640_461_4613_eslint_648_4657bWx3yCoGtnMeta?.path ?? "/live-stream-with-coinecta-on-cardano-spot",
    meta: nuxt_643_4610_463__64parcel_43watcher_642_464_461__64types_43node_6420_4611_4624_encoding_640_461_4613_eslint_648_4657bWx3yCoGtnMeta || {},
    alias: nuxt_643_4610_463__64parcel_43watcher_642_464_461__64types_43node_6420_4611_4624_encoding_640_461_4613_eslint_648_4657bWx3yCoGtnMeta?.alias || [],
    redirect: nuxt_643_4610_463__64parcel_43watcher_642_464_461__64types_43node_6420_4611_4624_encoding_640_461_4613_eslint_648_4657bWx3yCoGtnMeta?.redirect,
    component: () => import("/app/node_modules/.pnpm/nuxt@3.10.3_@parcel+watcher@2.4.1_@types+node@20.11.24_encoding@0.1.13_eslint@8.57.0_op_7389cdbc4aa1eccdb186f846b074abf7/node_modules/nuxt/dist/pages/runtime/component-stub").then(m => m.default || m)
  },
  {
    name: nuxt_643_4610_463__64parcel_43watcher_642_464_461__64types_43node_6420_4611_4624_encoding_640_461_4613_eslint_648_4657bWx3yCoGtnMeta?.name ?? undefined,
    path: nuxt_643_4610_463__64parcel_43watcher_642_464_461__64types_43node_6420_4611_4624_encoding_640_461_4613_eslint_648_4657bWx3yCoGtnMeta?.path ?? "/sitemap.xml",
    meta: nuxt_643_4610_463__64parcel_43watcher_642_464_461__64types_43node_6420_4611_4624_encoding_640_461_4613_eslint_648_4657bWx3yCoGtnMeta || {},
    alias: nuxt_643_4610_463__64parcel_43watcher_642_464_461__64types_43node_6420_4611_4624_encoding_640_461_4613_eslint_648_4657bWx3yCoGtnMeta?.alias || [],
    redirect: nuxt_643_4610_463__64parcel_43watcher_642_464_461__64types_43node_6420_4611_4624_encoding_640_461_4613_eslint_648_4657bWx3yCoGtnMeta?.redirect,
    component: () => import("/app/node_modules/.pnpm/nuxt@3.10.3_@parcel+watcher@2.4.1_@types+node@20.11.24_encoding@0.1.13_eslint@8.57.0_op_7389cdbc4aa1eccdb186f846b074abf7/node_modules/nuxt/dist/pages/runtime/component-stub").then(m => m.default || m)
  }
]